import styled from 'styled-components';

const Lozenge = styled.div`
  width: 150px;
  height: 150px;
  background: linear-gradient(to right, #4a90e2, #8e44ad);
  transform: rotate(45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 0px 35px -40px 35px;
`;

export { Lozenge };
