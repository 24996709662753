import React, { useState } from 'react';
import styled from 'styled-components';
import myImage from '../assets/images/backCoverDiscord-removebg-preview.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 2rem;
`;

const GradientTextTitle = styled.span`
  font-size: 2.5rem;
  background: linear-gradient(to right, #4a90e2, #8e44ad);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: 0;
  line-height: 1;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 10px;
`;

const Form = styled.form`
width: 100%;
display: flex;
flex-direction: column;
border-radius: 15px;
padding: 2rem;
box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.5);
`;

const FormField = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #ffffff;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const Title = styled.h2`
  color: #ffffff;
  margin-top: 2rem;
`;

const UnlinkLicense = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [license, setLicense] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiEndpoint = 'https://perfectune.atsys.ca/api/SerialNumber/UnlinkLicense';
    const requestData = {
      username: username,
      email: email,
      license: license,
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
    
      const data = await response.json();
    
      if (!response.ok) {
        console.error('Server error:', data.message || response.statusText);
        alert(`Error: ${data.message || response.statusText}`);
      } else {
        console.log('License Unlinked Successfully', data);
        alert('License has been unlinked successfully.');
      }
    } catch (error) {
      console.error('Network or other error:', error);
      alert('An error occurred. Please try again.');
    }
    
  };

  return (
    <Container>
      <MainContentWrapper>
        <GradientTextTitle>Unlink License</GradientTextTitle>
        <ContentWrapper>
          <ImageContainer>
            <Image src={myImage} alt="Your Image" />
          </ImageContainer>
          <FormContainer>
            <Form onSubmit={handleSubmit}>
              <FormField>
                <Label>Username:</Label>
                <Input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </FormField>
              <FormField>
                <Label>Email:</Label>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </FormField>
              <FormField>
                <Label>License:</Label>
                <Input
                  type="text"
                  value={license}
                  onChange={(e) => setLicense(e.target.value)}
                  required
                />
              </FormField>
              <SubmitButton type="submit">Unlink License</SubmitButton>
            </Form>
          </FormContainer>
        </ContentWrapper>
      </MainContentWrapper>
    </Container>
  );
};

export default UnlinkLicense;
