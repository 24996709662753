import React, { useState } from 'react';
import styled from 'styled-components';
import myImage from '../assets/images/backCoverDiscord-removebg-preview.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 2rem;
`;

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 10px;
`;

const RegisterForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;

const GradientTextTitle = styled.span`
  font-size: 2.5rem;
  background: linear-gradient(to right, #4a90e2, #8e44ad);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: 0;
  line-height: 1;
`;

const GalleryTitle = styled.h1`
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 1rem;
`;

const Message = styled.div`
  text-align: center;
  color: #d9534f; // Bootstrap's 'btn-danger' color for errors
`;


const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    try {
      const response = await fetch('/api/User/Register', {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      console.log('Registration successful!');
      setMessage('Registration successful! Please check your email to confirm.');
    } catch (error) {
      console.error('Registration failed:', error);
      setMessage('Registration failed. Please try again.');
    }
  };

  return (
    <Container>
      <TitleWrapper>
        <GradientTextTitle>Sign up to request access!</GradientTextTitle>
        <GalleryTitle>Start optimizing your gaming experience today!</GalleryTitle>
      </TitleWrapper>
      {message && <Message>{message}</Message>}
      <ContentWrapper>
        <FormContainer>
          <RegisterForm onSubmit={handleSubmit}>
            {/* Your form inputs */}
            <InputField
              type="text"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <InputField
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <SubmitButton type="submit">Register</SubmitButton>
          </RegisterForm>
        </FormContainer>
        <ImageContainer>
          <Image src={myImage} alt="Your Image" />
        </ImageContainer>
      </ContentWrapper>
    </Container>
  );
};

export default Register;
