import React, { useState } from 'react';
import styled from 'styled-components';
import myImage from '../assets/images/backCoverDiscord-removebg-preview.png';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 60vh;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-top: 2rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  
  border-radius: 10px;
`;

const Form = styled.form`
width: 100%;
display: flex;
flex-direction: column;
border-radius: 15px;
padding: 2rem;
box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  border-radius: 10px; // Optional, for aesthetic purposes
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
`;

const GradientTextTitle = styled.span`
  font-size: 2.5rem;
  background: linear-gradient(to right, #4a90e2, #8e44ad);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: 0;
  line-height: 2;
`;

const Message = styled.div`
  margin-top: 20px;
  color: ${props => props.error ? '#ff3860' : '#23d160'};
  font-size: 1rem;
  text-align: center;
`;

const ForgetLicense = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMessage('');
    setError(false);

    const encodedEmail = encodeURIComponent(email);
    const apiEndpoint = `https://perfectune.atsys.ca/api/SerialNumber/ForgetLicense/${encodedEmail}`;

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Accept': '*/*',
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      setMessage('Email sent with new license information. Please check your inbox.');
    } catch (error) {
      console.error('Error during license recovery process:', error);
      setError(true);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <Container>
      <MainContentWrapper>    
        <TitleWrapper>
          <GradientTextTitle>Forget License</GradientTextTitle>
        </TitleWrapper>
        <ContentWrapper>
          <ImageContainer>
            <Image src={myImage} alt="Forget License" />
          </ImageContainer>
          <FormContainer>
            <Form onSubmit={handleSubmit}>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <SubmitButton type="submit">Send</SubmitButton>
              {message && <Message error={error}>{message}</Message>}
            </Form>
          </FormContainer>
        </ContentWrapper>
      </MainContentWrapper>
    </Container>
  );
};

export default ForgetLicense;