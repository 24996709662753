import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Lozenge } from './common/Lozenge';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Register from '../pages/Register';
import translations from './resources/Translations';
import LanguageContext from './resources/LanguageContext';
import ForgetLicense from '../pages/ForgetLicense';
import UnlinkLicense from '../pages/UnlinkLicense';

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  margin: 0px;
  background-color: transparent;
  &:hover {
    color: lime;
  }
`;

const LinkText = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotate(-45deg);
`;

const Menu = ({ openModal }) => {
    const handleMenuItemClick = (content) => {
        openModal(content);
    };

    const { language } = React.useContext(LanguageContext);
    const menuTranslations = translations[language].menu;

    return (
        <MenuContainer>
            <Row>
                {/* <Lozenge onClick={() => handleMenuItemClick(<About/>)}>
                <StyledLink>
                    <LinkText><i class="bi bi-person-circle"></i>{menuTranslations.about}</LinkText>
                </StyledLink>
            </Lozenge> */}

                {/* <Lozenge onClick={() => handleMenuItemClick(<Contact />)}>
                <StyledLink>
                    <LinkText><i class="bi bi-person-plus"></i>{menuTranslations.contact}</LinkText>
                </StyledLink>
            </Lozenge> */}
            </Row>
            <Row>
                 <Lozenge onClick={() => handleMenuItemClick(<Register />)}>
                    <StyledLink>
                        <LinkText><i class="bi bi-gear"></i>{menuTranslations.register}</LinkText>
                    </StyledLink>
                </Lozenge>
            </Row>
            <Row>
                <Lozenge onClick={() => handleMenuItemClick(<ForgetLicense />)}>
                    <StyledLink>
                        <LinkText><i class="bi bi-folder"></i>{menuTranslations.forgetlicense}</LinkText>
                    </StyledLink>
                </Lozenge>
                <Lozenge onClick={() => handleMenuItemClick(<UnlinkLicense />)}>
                    <StyledLink>
                        <LinkText><i class="bi bi-image"></i>{menuTranslations.unlinklicense}</LinkText>
                    </StyledLink>
                </Lozenge>

            </Row>
        </MenuContainer>
    );
};

export default Menu;
